import React, { FC, ReactNode, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppTheme } from "../../utils/theme";
import { useMenuStore } from "../../stores/menu.store";
import MenuItemButton from "../../components/MenuItemButton";
import MenuItemIcon from "../../components/MenuItemIcon";
import getIcon from "../../utils/get-icon";
import { useNavigate } from "react-router-dom";
import { useRegisterBeneficiaryModal } from "../../components/modals/RegisterBeneficiary/RegisterBeneficiaryModalContext";
import { useUserStore } from "../../stores/user.store";
import useCurrentPath from "../../hooks/useCurrentPath";
import BeneficiarySearchBar from "../../components/BeneficiarySearchBar";
import { useSettingsV2 } from "../../hooks/useSetting";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(2.5),
	marginTop: "82px",
	height: "calc(100vh - 82px)",
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	height: "82px",
	display: "flex",
	flexDirection: "row",
	backgroundColor: "#fff",
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

type LayoutProps = {
	children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
	const { user, logout } = useAuth0();
	const routePath = useCurrentPath();
	const { userHasAccess, userHasAccessToCode } = useUserStore();
	const { data: settings } = useSettingsV2();
	const { openModal } = useRegisterBeneficiaryModal();
	const navigate = useNavigate();
	const theme = useAppTheme();
	const [open, setOpen] = useState(true);
	const { menuItems } = useMenuStore();
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const roleBeneficiary = "PART_VIEW";
	const routeBeneficiary = "/beneficiaries";
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logoutWithRedirect = async () => {
		await logout({
			logoutParams: {
				returnTo: window.location.origin,
				federated: true,
			},
		});
	};

	const handleDrawerOpen = () => setOpen(true);
	const handleDrawerClose = () => setOpen(false);

	if (!userHasAccess(routePath) && routePath !== "/callback") {
		location.replace("/unauthorized");
	}

	useEffect(() => {
		const inactivityLimit = settings?.inactivityTimeout
			? settings.inactivityTimeout * 1000 // Convert seconds to milliseconds
			: 15 * 60 * 1000; // Default to 15 minutes
		let lastActivityTime = Date.now();

		const updateLastActivity = () => {
			lastActivityTime = Date.now();
		};

		const checkInactivity = () => {
			if (Date.now() - lastActivityTime > inactivityLimit) {
				logout({
					logoutParams: {
						returnTo: window.location.origin,
						federated: true,
					},
				});
			}
		};

		// List of events to detect user activity
		const activityEvents = ["mousemove", "keydown", "click", "scroll"];

		// Attach event listeners
		activityEvents.forEach((event) => {
			window.addEventListener(event, updateLastActivity);
		});

		// Set up interval to check for inactivity
		const intervalId = setInterval(checkInactivity, 5 * 1000); // Check every 5 seconds

		// Cleanup function
		return () => {
			clearInterval(intervalId);
			activityEvents.forEach((event) => {
				window.removeEventListener(event, updateLastActivity);
			});
		};
	}, [logout]);

	return (
		<Box
			sx={{ display: "flex", backgroundColor: theme.palette.surface.main }}
			data-testid="layout"
		>
			<CssBaseline />
			<AppBar position="fixed" open={open} className="flex">
				<Toolbar className="flex-1 gap-4">
					<IconButton
						size="large"
						edge="start"
						aria-label="menu"
						data-testid="drawer-button"
						onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
					>
						<MenuIcon />
					</IconButton>

					{!routePath.includes(routeBeneficiary) &&
					userHasAccessToCode(roleBeneficiary) ? (
						<>
							<BeneficiarySearchBar />
							<Button
								size="large"
								onClick={() => openModal()}
								color="primary"
								variant="contained"
								sx={{
									textTransform: "none",
									height: "3rem",
									textAlign: "center",
								}}
							>
								REGISTER BENEFICIARY
							</Button>
						</>
					) : null}
				</Toolbar>

				<Box
					sx={{
						marginLeft: "auto",
						alignItems: "center",
						display: "flex",
						paddingLeft: "1rem",
						paddingRight: "1rem",
					}}
				>
					{false && (
						<Tooltip title="Notifications">
							<IconButton size="large">
								<NotificationsNoneOutlinedIcon className="text-icon-primary" />
							</IconButton>
						</Tooltip>
					)}
					<Tooltip title="Open settings">
						<Button
							size="large"
							onClick={handleOpenUserMenu}
							sx={{
								textTransform: "none",
								width: "240px",
								justifyContent: "space-between",
							}}
							data-testid="user-button"
						>
							<div className="flex items-center gap-4 w-full">
								<div className="flex flex-col flex-1 justify-start items-start">
									<span className="text-base text-primary font-bold">
										{user?.nickname}
									</span>
									<span className="text-xs text-secondary">{user?.email}</span>
								</div>
								<KeyboardArrowDownIcon className="text-dark" />
							</div>
						</Button>
					</Tooltip>
					<Menu
						sx={{ mt: "45px" }}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
						data-testid="user-menu"
					>
						<MenuItem
							onClick={() => {
								navigate("/help-and-support");
							}}
							data-testid="menu-item"
						>
							<Typography textAlign="center">Support</Typography>
						</MenuItem>
						<MenuItem
							onClick={logoutWithRedirect}
							data-testid="menu-item-logout"
						>
							<Typography textAlign="center">Logout</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</AppBar>

			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
						flexDirection: "column",
						backgroundColor: theme.palette.menu.main,
						height: "100vh",
						padding: "1.25rem 0.5rem",
						gap: "1rem",
					},
				}}
				variant="persistent"
				anchor="left"
				open={open}
				data-testid="drawer"
			>
				<div className="flex flex-col gap-2 items-center justify-center">
					<img
						src={settings?.brandInformation.logo ?? "/assets/sims-logo.png"}
						alt={settings?.brandInformation.title}
						className="w-32 h-32 mx-auto"
					/>
					<div className="flex flex-col items-center gap-2">
						<Typography
							variant="h4"
							className="text-center max-w-xs"
							sx={{ color: theme.palette.surface.main, fontWeight: 900 }}
						>
							{settings?.brandInformation.title}
						</Typography>
						<Typography
							className="text-center max-w-xs"
							sx={{
								color: theme.palette.surface.main,
								fontStyle: "italic",
								fontSize: "12px",
							}}
						>
							{settings?.brandInformation.subtitle}
						</Typography>
					</div>
				</div>
				{/* TODO: Read the route param to set active or open item on menu */}
				<List className="px-2.5">
					{menuItems.map((item) => (
						<div key={item.id}>
							<ListItem disablePadding className="mb-2">
								<MenuItemButton
									active={item.active}
									onClick={() => {
										if (item.type === "link") {
											if (item.route.startsWith("http")) {
												window.open(item.route, "_blank");
											} else {
												useMenuStore.getState().setActive(item.id);
												navigate(item.route);
											}
										} else {
											useMenuStore.getState().toggleOpen(item.id);
										}
									}}
								>
									<MenuItemIcon>{getIcon(item.icon)}</MenuItemIcon>
									<ListItemText
										primary={item.title}
										primaryTypographyProps={{ variant: "bodyLargeBold" }}
									/>
									{item.type === "parent" &&
										(item.open ? <ExpandLess /> : <ExpandMore />)}
								</MenuItemButton>
							</ListItem>
							{item.children && (
								<Collapse in={item.open} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{item.children.map((child) => (
											<ListItem key={child.id} disablePadding>
												<MenuItemButton
													onClick={() => {
														useMenuStore.getState().setActive(child.id);
														navigate(child.route);
													}}
												>
													<MenuItemIcon>{getIcon(child.icon)}</MenuItemIcon>
													<ListItemText
														primary={child.title}
														primaryTypographyProps={{
															variant: "bodyLargeBold",
														}}
													/>
												</MenuItemButton>
											</ListItem>
										))}
									</List>
								</Collapse>
							)}
						</div>
					))}
				</List>
			</Drawer>
			<Main open={open}>{children}</Main>
		</Box>
	);
};

export default Layout;
